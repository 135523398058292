export const imageJSON = {
  cpu_utilization:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKPSURBVHgBdVRRTttAEJ3ZBZevNjcgN4Ab4H5XajD8QKwqzQkC/xXr7QWAE5CIJlSqwMoJCidoeoKGEzT8kSo72xk7mzoWHclKdj379r2ZN0aoxVVuGlugzz34Jijot1tmIPvD3Gao4FdY10NVF6P888krUD8I3GU7MW+Vgxnv5dffTKyA+kg+lryb3DTleRFIDjALj+D7W9Hmb9k7OjBjT26stdo+TuyUyD+McvvdM2MCNMPc7K4BCToCzdLEXCLgLEis008PbV+YzsF10yTrcm4ngKl68hGDHb779BgBnsN/opvY4jJWgOSguQLyALseVCPoLhlCo16/0dh2qnsIenuh4b4AKmuDO6D9BYE2N1wD0T8Hf1o91E7OLqSTX3JzEvYcN2WLmfPFsZLaKPADdPSYJmddTgbRz/Sn17nZX6tRK7MK9F6o34fE3nuNfebWUcwm5qf3DDArE7AZEln3FJUy63Jo8jrafLNi+t48LHP9pJ1khQz2UO4cnYZCp4mdeCJbBeJ6NqQZYX1zaz4WQFzUWejEHKhbFg8nKwZK78ivsGR3X5D6927E0nm9/QzebrCspnTDET1p0B32yKn4STonxmMOInMSAeyjhzxtlVKW1+ylSzVYIN9xW9Hvs8SkoMtdYJCegEYEMaLy7QMzKOcQe2yVXU/+Z3posgBZ+AiRHoJEYUKgdrjdiXSuWh8ZZpYxkHew4b1YJ9gBw2H+a4657VCLIRdTGMllIrUAWYYwjGTunBsXjIqBZHLDW5sFacM8u1qHFJO6tYukQeI9rXUHqy/YgLGG0jcynGWtlHELshsR7jnyT3oBk+oZ/lw0QKsewgsxHGdGEcYE/v4P+MswpF/vbItqMygxRxr/BTFlVLPhHNIbAAAAAElFTkSuQmCC',
  storage_utilization:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIbSURBVHgBjZNBbtNQEIZn5tmQClBTiRUrB8S67hqJ2GsWSVBg2/QG5Qa9QbkB2bGAqOkJ7JyAsmKBqM0JcFS1qEneDPOc2DIVEhnJid/zy+Sf//uNoHWWJe2lhb4xtM8iEQK0dTuATem6YIAcEXIDmALw+aAT5+4ZTX4kxyyYEeEhMBQG6WSn5UdXc9kbPo3QXa2WHxqfjtnKOYNEVs9Pstlp2fzzZSr6nerBGLasT1kSoWACiCNvsxdOLtOMEN+vVvzV8yCvJG5GDJZuLAshGVUqEFXPyga8khgIQkHoood9KxCosqA6pOuC1Ac0oHJxejXnwaM2ntUNHjy89+vVkxdjvR3/V/73JNptU0+9KNfkPm6uF0ewZb15HqdseY6yplQqQIM99eBQeaUrdVql5m878UXTg4XO7xnqgshIGg1LCootuLU2sAvpI0mo6kJYZ6GqQq9cBFMWniFDTj4qRhyXCq5vF3v6jzO9nTXlfviStI8O4uKuB0yNkDkFWKYMTxB41sR3t6rE1ijrHCCO1dURCI61Yeoiq5uNRhKwjqU4QyS4QMapkCgBzGsPFONPJ3n3sdnXXITr90F/yFgQQaHG5Tv3vfT3zeJANBeOxnqELE30HciHz6KtUH78lgS+rybrGMNONPAMyMACnrooNzH6UMa5qDC6KPtAXQvSdxnipbwrFfzVuUU9WB9wCEP4B0ZDOH3deVnT+gPLAAs5Kj0TlwAAAABJRU5ErkJggg==',
  memory_utilization:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAE9SURBVHgB1VRBTsJQEJ35fNd4A/8ReoP2BoTEunFRr1CXImkI6JJ/BVyZAAl4AuEGvYIn0DW2HefbfGixLSy6cZLmz3t5/007MylAQzwudGTz6FWrwXIa1GkFtBStGeEx8TDXDiJc/lZBCDKCF5MTgWJ8RQBbg5/8cFNrNFhojwkXiTbQVB3RS3bw9nwbxpaTf0RsMr653zYZcUHsXFC3yMk68XCpV/w5cflNwBlfh/0qvSyOmIUKCGYmNyYTPxwVxUUtq+8Ye3ujotj06Pwx0mziH1rQ2vhltNLKgjTlsRN8nnMxE6D47sfeKNmRa4EQ6PCxNjkSOOWe5JzNO4SK71ZXMT0azqcunIgqXWn8UsJXmmDP7EmTETfW4+P9iDvEqB/GZiEFEeUPBDbvAHVFBr0cf69PLW0p/vdv5Ae+sITuYleJcQAAAABJRU5ErkJggg==',
  network_utilization:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAOCAYAAADwikbvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADKSURBVHgBpZJBDoIwEEVnGgndyRE4Arpx6w3wJrImJIQVS7wB8QbewsQNeAM9gbiiAWFsSUxISISmb9OfNr9/MjMImhRZ5jR1nSu9Ak2EEA4DuEjpMjBgNvmapntG5EtZ7sLwPH5bkuxafX9S52xyEceO4NzjQpSbJKngD5PkhvN4hbhubNuHGaZlE1UfojsswKzbtzTNCcCxui7YRtFTy0xEjx5Ry/TDqGxDM6Inf/Cxbd/DDaLa3SMxNsxYju3VMhYQojvyHZTvC3poPJmT1ucFAAAAAElFTkSuQmCC',
  error:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAADnUlEQVR42qRTTWwbVRD+3ttdr9f/jR03ThMnNQcuCKlRxZG0SIgLB8SpAqQIIS5cKxBwroqEOIFUpU3dUldNmgaBqCoqAaKgHhpxgOaSxI4SSKq4jh3Zjms76919u8zbBJM7I83O2/fmzXzzvRnWfLQIJagDRgi264KBhKx67Bicbgfr771/EZalZK9Mf8yjUU/UGwBn8MhN4woU0wRjDFxuSNFIKRwCvnoIJuLoPV6adFZWzzultfPW46XXIgMDMBSFVEWI1FBVMM7heR5Yc/F3OCAE/0akIDwcAdM0bJx7q2EWSwkoHHo2az43P2dQeohnzyCtlJ5tQzcMcIWcFIouaFPQoeNSoBPDqBRuftpZWkoERk4gkMmgu7IS3M5f+8xNp2A5DmwhfKsQIpWUNSRHmurDow+0VAr2zk5s/Z2pplOtMc8V/hkPBKDE4sjduJbWx8dq9k6VyiKuKLEnOSIL27Lh2A4cR0BQWeWZ/KX99RJTCdnJe3eRI1WHhtDb2kDlav6K0HVCLmDLO4RMODYlUhUEiLQAwQuNj6P3aPH51rffvU1gYUxMYPDMJAbPnoFx+jTtMOx9f/eN7q+/nQpmx6AQUtUvTQF3aCFVGEGyjDJeLXjtNtRkGr1SCSa1gilJLRWhDgyCWRaq128UXMl0LAZBjyKV23TJ2mvBTSRQv7PwevvHn14KZLPgkTBdXoNVLsMub6O3WgKPRqDRWfvBgxd2b82e8+Ix2M0mnFYLXA+HEM4ch9rtov51Ic8D1E2yP2SmVh3Wxl/okcq13JPPrhCP9cLNy2g0EZKvGg5Tad19sFQS9bnbH+7/8WdazQz5nS1FdopZLMJcKaIvkpfjaVjLy7HGrdkLPB6HMCnGnnQsP41uTr3b5KbJ5YF0ZoTKqVbBk0kfhVvb9QN41Dv+PzWlS3b8ej4dyuVq3KBmay9885XY2uKceJJBpIhGA9roKMZmLiM7Mw1tbBT+nB2i4pLoSgV783emNapIrd374cXa7O0pfWS0X5IfiEjUJ04hOfmy/78zPIze8ioFiB44kC+NDXbnF940Xjk7oUx1unNuae0kH0z10UhRKKO9uYXO35uo37+P9s+/QJU+hzN2MOn07J0Ouk+evKoyrjie1QWTaIT4z4kuqJEIGpemaYw96LncwRgdQS1DevKxAIdVHj7MbH/0yZeiXB5R6KJ36HBUWB9F/9RfCOpBquRp9ovPP2DekXL+j/wjwACWjabHv1ooiwAAAABJRU5ErkJggg==',
};
